import React from 'react'
// import { Container } from './style'

import { Details } from '../../components/Details'

export const DetailsPage = () => {
  return (
    <Details/>
  )
}
